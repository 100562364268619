<template>
  <div class="eula contentWrapper" v-bind:class="{ themeBlack: theme ==='black' }">
    <h1>TrackIR SDK EULA</h1>

    <div class="content">
      <h3>END USER LICENSE AGREEMENT FOR NATURALPOINT TRACKIR SDK</h3>
      <p>This  Agreement  grants  You  (<b>"You"</b>  or  <b>"Your"</b>)  a  limited  license  to  use  the  TrackIR  SDK,  subject  to  the  terms  and conditions provided in this document. A summary of key points is listed below:</p>

      <ul>
        <li>You are permitted to create applications or products that integrate the TrackIR SDK, with the limitations outlined below. Those applications or products are subject to the terms in this document.</li>
        <li>You are prohibited from using this TrackIR SDK to create or enable the use of competitive products.</li>
        <li>If you use this TrackIR SDK to enable TrackIR compatibility with your game, you grant NaturalPoint the right to  state  that  your  games  may  be  used  with  TrackIR,  but  only  after  the  public  release  of  your  game  or application.</li>
        <li>NaturalPoint  will  not  support  any  product  you  create  with  this  TrackIR  SDK.  You  may  request  help integrating, but we are not obligated to provide support.</li>
        <li>You are prohibited from using this TrackIR SDK in any high-risk applications, as defined below.</li>
      </ul>

      <p>NaturalPoint, Inc. is the legal entity that owns and maintains the brand “TrackIR”.</p>

      <p>This EULA does not grant you any right or interest in or to NaturalPoint’s TrackIR software. NaturalPoint’s TrackIR Software is licensed under separate license terms and conditions accessible here: <a href="https://www.trackir.com/eula">https://www.trackir.com/eula</a></p>
      <hr>

      <p>IF YOU DOWNLOAD, INSTALL, ACCESS OR USE THE TRACKIR SDK, YOU ACCEPT AND AGREE TO BE BOUND BY THIS EULA.   IF YOU ARE AN INDIVIDUAL ACTING ON BEHALF OF AN ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE  THE  AUTHORITY  TO  ENTER  INTO  THIS  EULA  ON  BEHALF  OF  THAT  ENTITY.  IF  YOU  DO  NOT  HAVE  SUCH AUTHORITY, YOU ARE SOLELY RESPONSIBLE FOR YOUR USE OF THE TRACKIR SDK. IF YOU DO NOT ACCEPT THE TERMS OF THIS EULA, THEN YOU ARE NOT PERMITTED TO INSTALL, ACCESS, DOWNLOAD OR OTHERWISE USE THE TRACKIR SDK.</p>

      <ol>
        <li><b>TrackIR SDK.</b>  This End User License Agreement (<b>"EULA"</b>) governs Your use of the TrackIR SDK, formerly known as the TrackIR Enhanced SDK or TrackIR Enhanced Interface Software (<b>"TrackIR SDK"</b>), is defined to  mean  NaturalPoint’s  proprietary:  (a)  user  documentation  provided  in  printed  and/or  electronic media;  (b)  application  programs,  utilities,  and  tools  a  (c)  sample  or  demonstration  software  code; and/or (d) any updates or upgrades thereto (to the extent updates or upgrades are made available by NaturalPoint).</li>
        <li><b>License Grant.</b>  Subject to Your payment of applicable fees, if any, and Your strict compliance with the terms and conditions of this EULA, NaturalPoint Inc. ("NaturalPoint") hereby grants to You, during the term of this EULA, a limited, revocable, non-exclusive, non-transferrable (except as permitted in this Section 2), non-sublicensable (except as permitted in this Section 2) license to:
        <ol type="a">
          <li>use the TrackIR SDK solely to develop, test and create Your own products as authorized in Section 4(b) below (“Product(s)”); and</li>
          <li>copy, distribute, and sublicense the distributable elements of the TrackIR SDK solely as part of the Products  for  installation  on  Your  end-customers’  devices  and  subject  to  the  terms  of  a  legally binding  license  agreement  between  You  and  the  end-customer  that  complies  with  Section  4(c) below.</li>
        </ol>
        The  foregoing  license  rights  are  non-transferable  and  non-sublicensable,  provided  that,  You  may sublicense the license rights granted in this Section 2 above solely to Your third party distributors so as to  permit  such  third  party  distributors  to  distribute  and/or  make  available  the  Products  to  end-customers.
        </li>
        <li><b>Ownership and Reservation of Rights.</b>  The TrackIR SDK is licensed and not sold. NaturalPoint and/or its affiliates or licensors will and do retain all right, title and interest in and to the TrackIR SDK, and any and  all  patent,  copyright,  trademark,  trade  secret,  and  any  other  intellectual  property  or  industrial rights  in  and  to  or  relating  to  the  TrackIR  SDK,  including  any  modifications,  improvements,  updates, and  derivative  works  thereof  or  thereto.  NaturalPoint  reserves  all  rights  and  interests  in  and  to  the TrackIR  SDK  and  all  other  NaturalPoint  intellectual  property  rights  that  are  not  expressly  granted  to You under this EULA, and You do not acquire any other rights, whether express, implied or otherwise, in the TrackIR SDK or any other NaturalPoint intellectual property rights or technology other than those rights expressly granted under this EULA.</li>
        <li>
          <b>Restrictions and Obligations.</b>
          <ol type="a">
            <li><u>Restrictions</u>. Except as expressly permitted herein, You will not, and will not permit, encourage, or enable any third party to: (i) sell, sublicense, rent, loan or lease any portion of the TrackIR SDK to any third party; (ii) modify, disassemble, decompile or reverse engineer any part of the TrackIR SDK (except that You may modify any sample or demonstration source code included in the TrackIR SDK); (iii) copy (except for reasonable backup purposes and with all labelling and proprietary rights notices intact) or otherwise reproduce the TrackIR SDK, in whole or in part, or modify, adapt, alter, translate or incorporate into or with other software or technology or create a derivative work of any part of, the TrackIR SDK; (iv) remove, modify, or otherwise tamper with proprietary rights notices or legends on the TrackIR SDK; (v) use the TrackIR SDK in any manner to provide service bureau, time sharing or  other  computer  services  to  third  parties;  (vi)  distribute  the  SDK  (other  than  the  distributable elements of the TrackIR SDK used or incorporated in the Products in accordance with this EULA); (vii)  use  the  TrackIR  SDK  in  or  as  part  of  the  development  of  any  product  or  service  that  is competitive with or similar to NaturalPoint hardware or software; or (viii) enable the TrackIR SDK to interface with any product or service that is competitive with or similar to NaturalPoint hardware or  software;  or  (ix)  disclose  the  results  of  any  performance  benchmark  or  similar  testing  of  the TrackIR SDK to any third party without NaturalPoint’s prior written consent.</li>
            <li>
              <u>Products</u>.  You agree as follows with respect to the Products:
              <ol type="i">
                <li>The  Products  shall  not  substantially  replicate  the  functionality  of  the  products  or  services offered by NaturalPoint.</li>
                <li>You acknowledge and agree that You are solely responsible for, and that NaturalPoint has no responsibility or liability of any kind in connection with, the content, development, operation, support  or  maintenance  of  any  Products.  Without  limiting  the  foregoing,  You  will  be  solely responsible  for:  (1)  the  technical  installation  and  operation  of  the  Products;  (2)  creating  and displaying  information  and  content  on,  through  or  within  the  Products;  (3)  ensuring  that  the Products do not violate or infringe the intellectual property rights of any third party; (4) ensuring that the Products are not offensive, profane, obscene, libelous or otherwise illegal or in violation of applicable laws; (5) ensuring that the Products do not contain or introduce any virus, Trojan horse, worm, software lock, drop dead device or any other limiting routine or harmful code into any NaturalPoint or end-customer IT systems, networks, hardware or software; and (6) ensuring that the Products are not designed to or utilized for the purpose of spamming end users or any third parties.</li>
                <li>You  acknowledge  and  agree  that  You  are  solely  responsible  for  providing  all  support  and technical  assistance  to  end-customers  of  the  Products.  NaturalPoint  has  no  obligation  to provide such support or technical assistance to end-customers or their users, and You agree not to  represent  to  end-customers  or  their  users  that  NaturalPoint  is  available  to  provide  such support or technical assistance.</li>
              </ol>
            </li>
            <li><u>End-Customer  Agreement</u>.    You  agree  to  cause  each  end-customer  to  whom  You  provide  or distribute a Product to agree to a binding end user license agreement including terms and conditions that  are  at  least  as  protective  of  the  TrackIR  SDK  and  NaturalPoint’s  intellectual  property  rights associated  therewith  or  embodied  therein  as  the  terms  and  conditions  of  this  EULA.    Such agreement  shall  further  disclaim  any  and  all  representations  and  warranties  on  the  part  of NaturalPoint, and exclude and disclaim all liability of any kind on the part of NaturalPoint.</li>
            <li><u>Testing</u>.   You will be solely responsible for the testing and evaluation of the TrackIR SDK for Your purposes.</li>
            <li><u>Compliance  with  Laws;  Privacy</u>.  You  agree  that  in  using  the  TrackIR  SDK,  You  will  comply  with  all applicable laws and protect the privacy and legal rights of end-customer users.  Without limiting the foregoing, You agree to prominently display a privacy notice that describes to end-customer users the  information that is collected  by You  and Your  Product and  how  such information  is used and shared. You will maintain and process all end-customer user information in accordance with Your privacy notice and all applicable laws in any countries in which the Products are used.</li>
          </ol>
        </li>
        <li><b>Trademarks.</b>  To the extent the TrackIR SDK displays NaturalPoint’s trademark, trade names, logos or other branding (the <b>"NaturalPoint Marks"</b>), NaturalPoint hereby grants to You a limited, non-exclusive, non-transferrable, non-sublicensable, revocable, royalty-free license, during the term of this EULA only and subject to the terms and conditions set forth herein, to display the NaturalPoint Marks as part of the normal operation of the TrackIR SDK.  You acknowledge NaturalPoint’s ownership of and exclusive rights in and to the NaturalPoint Marks and all goodwill associated therewith, and acknowledge and agree that any and all use of the NaturalPoint Marks shall inure to the sole and exclusive benefit of NaturalPoint.   You agree not to take any action or engage in any conduct that is inconsistent with or contrary  to  NaturalPoint’s  sole  and  exclusive  rights  in  and  ownership  of  the  NaturalPoint  Marks.  NaturalPoint  does  not  grant  You  any  other  rights  to  use  the  NaturalPoint  Marks,  whether  by implication, estoppel or otherwise.</li>
        <li><b>Support Services.</b>  NaturalPoint is not obligated under this EULA to provide any technical support for the TrackIR SDK.  If any such technical support is provided, such support is provided is “as is”, with all faults”, and without representations or warranties of any kind.</li>
        <li><b>Updates.</b>  NaturalPoint, at its discretion, may make available to You updates or upgrades to the TrackIR SDK.  In the event that NaturalPoint provides any such updates or upgrades, the terms and conditions of  this  EULA  will  govern  any  such  updates  or  upgrades,  except  that  if  such  upgrades  or  updates  are accompanied by a separate set of terms, those terms will govern and control the use of the upgrades or updates to the extent of any conflict with or terms that are in addition to this EULA.</li>
        <li><b>Disclaimer.</b> THE TRACKIR SDK IS PROVIDED “AS IS” AND WITH ALL FAULTS.   NATURALPOINT AND ITS AFFILIATES HEREBY EXPRESSLY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NATURALPOINT DOES NOT REPRESENT OR WARRANT THAT THE TRACKIR SDK WILL MEET YOUR REQUIREMENTS, THAT THE TRACKIR  SDK  IS  COMPATIBLE  WITH  ANY  PARTICULAR  HARDWARE  OR  SOFTWARE  PLATFORM(S),  OR THAT  THE  USE  OF  THE  TRACKIR  SDK  WILL  BE  UNINTERRUPTED,  ERROR-  FREE,  OR  FREE  FROM  ANY INFRINGEMENT  OF  ANY  INTELLECTUAL  PROPERTY  RIGHT(S),  INCLUDING  BUT  NOT  LIMITED  TO, PATENTS,  UTILITY  MODELS,  TRADEMARKS  OR  COPYRIGHTS,  OR  THAT  DEFECTS  IN  THE  TRACKIR  SDK WILL  BE  CORRECTED.    FURTHERMORE,  NATURALPOINT  AND  ITS  AFFILIATES  DO  NOT  MAKE  ANY REPRESENTATION OR WARRANTY REGARDING THE USE BY YOU OF THE TRACKIR SDK (INCLUDING THE RESULTS   GENERATED   BY   SUCH   USE),   INCLUDING   WITHOUT   LIMITATION,   THE   CORRECTNESS, ACCURACY, QUALITY, CURRENTNESS, OR RELIABILITY OF THE TRACKIR SDK OR ANY CORRESPONDING RESULTS, OR THE APPROPRIATENESS OF THE TRACKIR SDK OR ANY CORRESPONDING RESULTS FOR A PARTICULAR  TASK  OR  APPLICATION.   NATURALPOINT  AND  ITS  AFFILIATES  DO  NOT  WARRANT  THAT YOU WILL BE ABLE TO DEVELOP OR MAKE A PRODUCT USING THE TRACKIR SDK.  NO ORAL OR WRITTEN INFORMATION  OR  ADVICE  GIVEN  BY  NATURALPOINT  OR  ITS  REPRESENTATIVES  SHALL  CREATE  A REPRESENTATION OR WARRANTY ON THE PART OF NATURALPOINT WITH RESPECT TO THE TRACKIR SDK.  ALL RISK ASSOCIATED WITH THE USE OF THE TRACKIR SDK IS WITH YOU.</li>
        <li><b><u>High Risk Applications</u>.</b>  THE TRACKIR SDK IS NOT DESIGNED, MANUFACTURED, OR INTENDED FOR USE IN ENVIRONMENTS REQUIRING FAULT TOLERANCE OR FAIL-SAFE PERFORMANCE, SUCH AS IN THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION, MEDICAL OR COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, DIRECT LIFE SUPPORT MACHINES, OR WEAPON SYSTEMS, IN WHICH THE FAILURE OF THE SOFTWARE COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE ("HIGH RISK APPLICATIONS"). NAUTRALPOINT AND ITS LICENSORS SPECIFICALLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY OF FITNESS FOR HIGH RISK APPLICATIONS.</li>
        <li><b>Limitation of Liability.</b>  IN NO EVENT WILL NATURALPOINT AND ITS AFFILIATES BE LIABLE TO YOU OR TO  ANY  THIRD  PARTY  FOR  ANY  INDIRECT,  INCIDENTAL,  SPECIAL,  CONSEQUENTIAL  OR  PUNITIVE DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUE, BUSINESS, SAVINGS, DATA, USE, OR COST OF  SUBSTITUTE  GOODS  OR  SERVICES,  REGARDLESS  OF  THE  CAUSE  OF  ACTION  UNDER  WHICH  SUCH DAMAGES  ARE  SOUGHT  (INCLUDING  AN  ACTION  IN  CONTRACT  OR  TORT),  AND  REGARDLESS  OF WHETHER  NATURALPOINT  HAS  BEEN  ADVISED  OF  THE  POSSIBILITY  OF  SUCH  DAMAGES  OR  IF  SUCH DAMAGES  WERE  FORESEEABLE.  YOU  ACKNOWLEDGE  THAT  THE  LIMITATIONS  OF  LIABILITY  IN  THIS SECTION AND IN THE OTHER PROVISIONS OF THIS AGREEMENT AND THE ALLOCATION OF RISK HEREIN ARE   AN   ESSENTIAL   ELEMENT   OF   THE   BARGAIN   BETWEEN   THE   PARTIES,   WITHOUT   WHICH NATURALPOINT WOULD NOT HAVE ENTERED INTO THIS AGREEMENT. IN THE EVENT OF ANY CLAIM RELATED IN ANY WAY TO THIS AGREEMENT OR THE TRACKIR SDK, YOU ACKNOWLEDGE AND AGREE THAT  NATURALPOINT’S  AND  ITS  AFFILIATES’  TOTAL  AGGREGATE  LIABILITY  TO  YOU  UNDER  THIS AGREEMENT  SHALL  NOT  EXCEED  THE  GREATER  OF  THE  AMOUNT  OF  ONE-HUNDRED  U.S.  DOLLARS (U.S. $100 NOTHING IN THIS SECTION EXCLUDES OR LIMITS NATURALPOINT’S LIABILITY FOR MATTERS FOR WHICH LIABILITY CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</li>
        <li><b>Indemnification.</b>    You agree to defend, indemnify and hold harmless NaturalPoint and its affiliates, and each of their respective officers, directors, agents, and employees, for, from, and against any and all claims, demands, loss, damage, liability, cost, or expense (including, but not limited to, attorneys' fees at trial, on appeal, and on any petition for review), arising out of or related to: (a) Your breach or failure to comply with any Open Source Software license or other third party software license; (b) Your breach  of  this  EULA;  (c)  the  conduct  of  Your  business;  (d)  Your  use  of  the  TrackIR  SDK  or  the NaturalPoint Marks; (e) Your Products; or (f) Your infringement, misappropriation, or violation of the intellectual property rights or other proprietary rights of any third party.</li>
        <li>
          <b>Termination.</b>
          <ol type="a">
            <li>This EULA commences on the date You click to accept the terms and conditions of this EULA when provided the opportunity to do so, and will continue in effect until terminated.  You may terminate this  EULA  by  notifying  NaturalPoint  in  writing  that  You  have  ceased  all  use  of  the  TrackIR  SDK. Additionally, Your rights and licenses under this EULA will automatically terminate and cease to be effective, without any notice or action by NaturalPoint, in the event that You breach or otherwise fail to comply with any term of this EULA.</li>
            <li>Upon the termination of this EULA, You will cease all use of the TrackIR SDK and permanently delete and make unrecoverable the TrackIR SDK and all copies thereof from Your computer and any other device on which it was installed. Upon any termination of this EULA, Sections 1, 3, 8, 9, 10, 11(b), and 12-17 will survive.</li>
          </ol>
        </li>
        <li>
          <b>Governing Law and Venue; Governing Language.</b>
          <ol type="a">
            <li><u>Governing Law</u>.  This EULA is governed by the laws of the State of Oregon and the United States of America,  without  regard  to  conflict  of  law  principles.  The  United  Nations  Convention  for  the International Sale of Goods shall not apply. Any dispute relating in any way to the TrackIR SDK or this EULA will only be adjudicated in a state or federal court located in Multnomah County, Oregon.  Each  party  consents  to  exclusive  jurisdiction  and  venue  in  these  courts.    Notwithstanding  the foregoing, any party may seek injunctive relief in any state, federal, or national court of competent jurisdiction for any actual or alleged infringement of such party’s, its affiliates’ or any third party’s intellectual property or other proprietary rights.</li>
            <li>ANY AND ALL DISPUTES, CLAIMS, AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THIS  AGREEMENT  AND/OR  THE  TRACKIR  SDK  SHALL  BE  RESOLVED  INDIVIDUALLY,  WITHOUT RESORT  TO  ANY  FORM  OF  CLASS  ACTION.   EACH  PARTY  IRREVOCABLY  AND  UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LEGAL ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY.</li>
          </ol>
        </li>
        <li>
          <b>U.S. Government Restricted Rights; Export Restrictions.</b>
          <ol type="a">
            <li><u>U.S. Government Restricted Rights</u>.  The TrackIR SDK is a "commercial item," as that term is defined at  48  CFR  2.101,  consisting  of  "commercial  computer  software"  and  "commercial  computer software documentation," as such terms are used in 48 CFR 12.212. Consistent with 48 CFR 12.212 and 48 CFR 227.7202-1 through 227.7202-4, all U.S. Government users acquire the TrackIR SDK with only those rights set forth  herein.</li>
            <li><u>Export Restrictions</u>.   You may not download, export or re-export the TrackIR SDK: (i) into, or to a national  or  resident  of,  any  country  to  which  the  United  States  has  embargoed  goods;  or  (ii)  to anyone  on  the  United  States  Treasury  Department's  list  of  Specially  Designated  Nationals  or  the U.S. Commerce Department's Table of Deny Orders. Accordingly, You hereby represent and warrant that You  are not located in, under the control of, or a national or resident of any such country or on  any  such  list.  You  acknowledge  that  it  is  Your  sole  responsibility  to  comply  with  any  and  all government  export  and  other  applicable  laws  and  that  NaturalPoint  has  no  responsibility  with respect thereto.</li>
          </ol>
        </li>
        <li>
          <b>Third Party Software.</b>
          <ol type="a">
            <li><u>Open Source Software</u>.  You shall not take any action that would require, indicate, or imply that the TrackIR SDK is or may be licensed under the terms of any Open Source Software license.  By way of illustration and not by way of limitation, You shall not use or incorporate the TrackIR SDK with any Open  Source  Software  licensed  under  any  license  terms  that:  (i)  impose  or  could  impose  a requirement or condition that the TrackIR SDK, or any software or source code used or integrated therewith: (1) be disclosed or distributed in source code form; (2) be licensed for the purpose of making modifications or derivative works; or (3) be redistributable at no charge; or (ii) otherwise impose or could impose any other material limitation, restriction, or condition on the right or ability of  NaturalPoint  to  use  or  distribute  the  TrackIR  SDK,  or  any  software  or  source  code  used  or integrated  therewith.  You  shall  promptly  inform  NaturalPoint  in  writing  of  any  Open  Source Software use in violation of this Section of which You become aware. For purposes of this Section, <b>“Open Source Software”</b> means any software code that is distributed as “free software” or “open source  software”  or  that  is  otherwise  distributed  publicly  in  source  code  form  under  terms  that permit modification and redistribution of such software.</li>
            <li><u>Third Party Software</u>.  The TrackIR SDK includes and uses the third-party software components listed at https://trackir.com/eula. You acknowledge and agree that such third-party software is subject to and governed by the license terms under which such third party software components are licensed and You agree to strictly comply with such license terms.  Any breach of such license terms by You shall be deemed a breach of this EULA.</li>
          </ol>
        </li>
        <li><b>Public  Reference  &amp;  TrackIR  Compatibility.</b>  You  consent  to  the  public  use  of  Your  personal  and/or corporate  name  as  a  customer  of  NaturalPoint,  unless  You  notify  NaturalPoint  in  writing  that  You withhold  such  consent.  As  a  condition  of  the  license  granted  in  Section  2,  You  also  consent  to  the publication of statements that any publicly released Products and related software designed by You are  compatible  with  TrackIR,  together  with  Your  personal  and/or  corporate  name,  the  name  of  any game related to the Products, and Your logo.</li>
        <li>
          <b>General Terms.</b>
          <ol type="a">
            <li><u>Records</u>.  You agree to keep complete and accurate records with respect to Your use of the TrackIR SDK  sufficient  to  determine  Your  compliance  with  the  terms  and  conditions  of  this  EULA.  NaturalPoint shall have the right to audit and inspect such records upon reasonable prior notice to You, and You agree reasonably cooperate with NaturalPoint in connection with any such audit and inspection.</li>
            <li><u>Severability</u>.  If any provision of this EULA is held to be illegal, invalid, or otherwise unenforceable, such provision will be enforced to the extent possible consistent with the stated intention of the parties, or, if incapable of such enforcement, will be deemed to be severed and deleted from this EULA, while the remainder of this EULA will continue in full force and effect.</li>
            <li><u>No Assignment</u>.    You will not transfer, assign, or delegate the TrackIR SDK or any of Your rights or obligations under this EULA, by operation of law or otherwise, without the prior written consent of NaturalPoint,  which  shall  not  be  unreasonably  refused,  withheld,  conditioned  or  delayed.  Any transfer, assignment, or delegation of any of Your rights or obligations under this EULA in violation of this Section is void and of no effect.</li>
            <li><u>Entire  Agreement;  General</u>.  This  EULA  constitutes  the  entire  agreement  between  You  and NaturalPoint and supersedes all prior or contemporaneous agreements or representations, written or oral, concerning the subject matter of this EULA.   This does not, and shall not be construed to, create   any   partnership,   joint   venture,   employer-employee,   agency,   or   franchisor-franchisee relationship between You and NaturalPoint. Any heading, caption, or section title contained herein is  inserted  only  as  a  matter  of  convenience,  and  in  no  way  defines  or  explains  any  section  or provision hereof. The waiver by either party of any default or breach of this EULA may only be made in writing and will not constitute a waiver of any other or subsequent default or breach.</li>
            <li><u>Changes  to  this  Agreement</u>.   This  EULA  may  be  unilaterally  modified,  changed  or  amended  by NaturalPoint   and   the   parties   agree   that   any   such   modifications,   changes   or   amendments (<b>“Amendments”</b>) shall be binding on the parties to the extent the Amendments: (i) are generally beneficial  to  the  parties;  or  (ii)  do  not  materially  conflict  with  the  subject  and  purpose  of  the transactions governed by this EULA, in addition to being reasonable in the light of the necessity and reasonableness  of  the  matters  changed  by  the  Amendments.     NaturalPoint  will  make  the Amendments  available  and  accessible  to  You  in  the  same  manner  in  which  NaturalPoint  makes available  the  EULA,  such  Amendments  to  be  made  available  and  accessible  in  advance  of  the effective date of such Amendments. </li>
          </ol>
        </li>
      </ol>



    </div>
  </div>
  <!-- END eula -->
</template>
<script>
export default {
  name: "Eula",
  props: ["theme"]
};
</script>
<style lang="scss" scoped>
.eula {
  text-align: center;

  h1 ~ p {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  h3 {
    margin-bottom: 20px;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    padding-bottom: 40px;
  }
  hr {
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  ol {
    margin-top: 20px;
  }

  &.themeBlack {
    color:#666666;

    h1,h2,h3 {
      color: #000;
    }
    a {
      color:#000;
      text-decoration: underline;
    }
  }
}
</style>